import React from "react";

const Contact = () => {
  return (
    <section className="contact">
      <article className="contactbx">
        <h3>Let's Get in touch !</h3>
        <p>
          Let's connect and collaborate!
          Reach out to me for any inquiries or just to say hello.
        </p>
        <button className="iconbtn">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/sanghdeep-ganvir-b2016a173/"
          >
            contact me <ion-icon name="send-outline"></ion-icon>
          </a>
        </button>
      </article>
    </section>
  );
};

export default Contact;

import Card from "../card/Card";
import calander from "../../assets/calander.png";
import { data, moreinfo } from "../../config/data";
import microphone from "../../assets/microphone.png";
import Box from "../container";
import hacks from ".././../assets/hackathons.jpg";

const Project = () => {
  return (
    <>
      <section className="projects" id="projects">
        <h2 className="boxheading">
          <img src={calander} alt="calander" /> projects
        </h2>

        <div className="projects__wrapper">
          {data.projects &&
            data.projects.map((project) => (
              <Card key={project.name} data={project} />
            ))}
        </div>
      </section>
      <Box data={moreinfo.competitive_programming} name="Competitive Programming" />
      <Box data={moreinfo.opensource} name="Open Source" />
    </>
  );
};

export default Project;

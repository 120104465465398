import Icon from "../../Icons";

const Socials = () => {
  return (
    <section className="socials" id="contact">
      <article className="socialbx">
        <h3>Connect with Me</h3>
        <p>Let's Stay Connected!</p>
        <div className="icon_bx">
          <a href="https://github.com/sangdeepganvir" className="icon">
            <Icon name="Github" color="black" size={15} />
          </a>
          <a href="https://leetcode.com/sanghdeep01/" className="icon">
            <Icon name="Code" color="black" size={15} />
          </a>
          <a href="https://instagram.com/sanghdeep.jnv" className="icon">
            <Icon name="Instagram" color="black" size={15} />
          </a>
          <a
            href="https://www.linkedin.com/in/sanghdeep-ganvir-b2016a173/"
            className="icon"
          >
            <Icon name="Linkedin" color="black" size={15} />
          </a>
        </div>
      </article>
    </section>
  );
};

export default Socials;

export const data = {
  role: "Web Developer",
  resumeLink:
    "https://drive.google.com/file/d/1wJgv2T9EEv2jkA_ymibQ7oE6bTSj1rQm/view?usp=drive_link",

  about: {
    bio: "A beginner web developer, competitive programmer, and open source enthusiast. Crafting visually stunning websites, solving coding challenges, and contributing to open source projects drive my passion. Eager to learn, grow, and explore emerging technologies.",
    education: [
      {
        name: "B.Tech in Computer Science",
        location: "IIT (ISM) Dhanbad ",
        date: "Dec 2021 - May 2025",
      },
      {
        name: "12th , CBSE",
        location: "J.N.V , Gondia (MH)",
        date: "2019 - 2020",
        percentage: "93%",
      },
      {
        name: "10th , CBSE",
        location: "J.N.V , Gondia (MH)",
        date: "2018 - 2019",
        percentage: "92.2%",
      },
    ],
  },
  projects: [
    {
      name: "AI+ Toolkit",
      description:
        "Developed a web app utilizing OpenAI API to make a suite of tools.",
      github: "https://github.com/sangdeepganvir/OpenAI-Tools",
      live: "https://openai-tools.onrender.com/",
      image: "openai",
    },

    {
      name: "Homzy",
      description: "Streamline Your Home Hunting with us",
      github: "https://github.com/sangdeepganvir/Real-Estate",
      live: "https://real-estate-7rq.pages.dev/",
      image: "realestate",
    },

    {
      name: "Huffman compressor",
      description: "Compress your text files with ease",
      github: "https://github.com/sangdeepganvir/HuffmanCompressor",
      live: "https://sangdeepganvir.github.io/HuffmanCompressor/",
      image: "huffman",
    },
    {
      name: "Notes-Flow",
      description: "Capture, Organize, and Stay Productive with Notes-Flow",
      github: "https://github.com/sangdeepganvir/Google-Keep-Clone",
      live: "https://github.com/sangdeepganvir/Google-Keep-Clone",
      image: "notesflow",
    },
    {
      name: "Drum Kit",
      description: "A Website Using HTML,CSS,Javascript ",
      github: "https://github.com/sangdeepganvir/Drum-Kit",
      live: "https://sangdeepganvir.github.io/Drum-Kit/",
      image: "drum",
    },
  ],
  socials: [
    { link: "https://github.com/sangdeepganvir", name: "Github" },
    { link: "https://leetcode.com/sanghdeep01/", name: "Leetcode" },
    { link: "https://instagram.com/sanghdeep01", name: "Instagram" },
    {
      link: "https://www.linkedin.com/in/sanghdeep-ganvir-b2016a173/",
      name: "Linkedin",
    },
  ],
};

export const moreinfo = {
  opensource: [
    {
      name: "LinksHub",
      program: "GSSOC'23",
      desc: "Contributed 'FinTech' subcategory to YouTube section. Curated links to fintech tools/resources, empowering users in exploring the industry.",
      link: "https://github.com/rupali-codes/LinksHub",
      image: "linkshub",
    },
    {
      name: "Projects Hut",
      program: "GSSOC'23",
      desc: "Contributed 'Huffman Compressor' project to ProjectsHut repository. Implemented efficient data compression using Huffman coding algorithm.",
      link: "https://github.com/priyankarpal/ProjectsHut",
      image: "projectshut",
    },
    {
      name: "Resource Gallery",
      program: "GSSOC'23",
      desc: "Added new 'Cybersecurity' section to Resource Gallery project. Included curated YouTube courses, recommended books resources for enhancing knowledge in cybersecurity.",
      link: "https://github.com/ZeroOctave/resource-gallery",
      image: "resourcegal",
    },
    {
      name: "Free-Hit",
      program: "GSSOC'23",
      desc: "Contributed the 'Enhancer for YouTube' extension to the Free Hit repository. It enhances the YouTube browsing experience by adding additional features and functionalities.",
      link: "https://github.com/JasonDsouza212/free-hit/pull/284",
      image: "freehit",
    },
  ],

  competitive_programming: [
    {
      name: "Pupil",
      program: "Rating : 1244 (max)",
      desc: "Solved more than 350 problems",
      link: "https://codeforces.com/profile/sanghdeep01",
      image: "codeforces",
    },
    {
      name: "Knight",
      program: "Rating : 1675",
      desc: "Solved more than 100 problems",
      link: "https://leetcode.com/sanghdeep01/",
      image: "leetcode",
    },
    {
      name: "3 star",
      program: "Rating : 1687",
      desc: "Solved more than 300 problems",
      link: "https://www.codechef.com/users/sanghdeep_01",
      image: "codechef",
    },
    {
      name: "5 star",
      program: "Rating : 1132",
      desc: "Solved 50+ problems in Problem Solving Section",
      link: "https://www.hackerrank.com/sangdeepganvir?hr_r=1",
      image: "hackerrank",
    },
  ],
};
